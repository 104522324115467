@import './theme';

$border-radius: 1rem;
$card-inner-border-radius: 1rem;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/litera/bootswatch';

.motives-church {
  .logo {
    img {
      max-width: 100%;
      max-height: 20rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .text-center {
    padding-top: 0.5rem;
    text-align: center;
  }

  .motive-container {
    .card {
      margin: 1rem 0;

      &.today {
        border: 2px solid #1760df;
        border-radius: 0.5rem;
        padding: 1rem;
        background-color: #d3e2fb;
      }

      .response-label {
        font-weight: bold;
      }

      .badge-secondary {
        background-color: #eb9800;
      }

      .response {
        border: 2px solid #ffc14e;
        border-radius: 0.5rem;
        padding: 1rem;
        background-color: #fff1d8;
      }
    }
  }
}

.home-page {
  background: url('./img/bg-homepage.jpeg') no-repeat center center fixed;
  background-size: contain;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: lighter;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;

  .menu {
    top: 50%;
    position: fixed;
    text-align: center;
    width: 100%;

    &.background {
      & > div {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;

        img {
          width: 100%;
        }
      }
    }

    &.buttons {
      & > div {
        margin-top: 10%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        max-width: 300px;

        a img {
          width: 100%;
          height: auto;
          margin: 0.1rem;
        }
      }
    }
  }
}

@media (min-width: 720px) {
  .home-page {
    .menu.buttons {
      & > div {
        margin-top: 70px;
      }
    }
  }
}
